/* Members.css */
.members-container {
    padding: 20px;
  }
  .topbar_member{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
font-size: 35px;
    gap: 10px;
  }
  .phone{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
  h1 {
    color: #004c4c;
    font-size: 32px;
  }
  
  .members-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
    animation: fadeIn 1.5s ease-out;
    animation-fill-mode: backwards;
  }
  
  .member-card {
    width: 270px;
    margin: 10px;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 10px;

  }
  
  .member-card img {
    width: 250px;
    height: 300px;
    border-radius: 10px;
  }
  
  .member-details {
    margin-top: 10px;
  }
  
  .member-details h2 {
    font-size: 20px;
    margin: 5px 0;
  }
  
  .member-details p {
    font-size: 16px;
    margin: 5px 0;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
