.main_div {
    padding: 70px 0;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;

}

.popup-content {
    position: relative;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 50vw;
    height: 90vh;
    overflow-y: scroll;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 80%;

}

.popup-content img {
    width: 100%;
    object-fit: contain;
    height: auto;
    margin-bottom: 15px;
}

.popup-content p {
    /* padding: 15px; */
    /* margin-top: 10px; */
    display: flex;
    /* width: 90%; */
    /* margin: auto; */
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.5;
    color: #ff0000;
    margin: 0;
}

.close-button {
    position: sticky;
    top: 10px;
    margin-left: auto;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #333;
}

.sticky-bar {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background-color: #ff0000;
    color: #fff;
    font-weight: 700;
    padding: 5px;
    /* height: auto; */
    align-items: center;
    height: 30px;
    display: flex;
    /* border-radius: 5px; */
    cursor: pointer;
    z-index: 9999;
}
.download-button{
    background: red;
    color: white;
    padding: 5px 15px;
    font-size: 20px;
    border: 1px rgb(165, 162, 162) solid;
    cursor: pointer;
    width: 80%;
    margin: 5px auto;
}
@media (max-width: 768px) {

    .popup-content {
        position: relative;
        background-color: white;
        display: flex;
        flex-direction: column;
        padding: 20px;
        width: 83%;
        height: 84%;
        overflow-y: scroll;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        max-width: 90%;
    }

    .popup-content img {
        width: 100%;
        object-fit: cover;
        height: 100%;
        margin-bottom: 15px;
    }
}