.footer-container {
    padding: 10px;
    text-align: center;
    background-color: #008080;
    margin-top: 50px;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.copyright-text {
    margin: 0;
    font-size: 14px;
    color: white;
}