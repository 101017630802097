/* Data.css */
.data-container {
    padding: 20px;
}

h1 {
    color: #004c4c;
    font-size: 32px;
}

.topbar_pdf {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    gap: 10px;
}

.topbar_pdf_new {
    display: flex;
    color: #004c4c;
    flex-direction: row;
    justify-content: start;
    width: 90%;
    margin: auto;
    align-items: center;
    font-size: 18px;
    gap: 10px;
}

.pdf-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    width: 90%;
    margin: auto;
    margin-top: 20px;
    border: 2px solid rgba(128, 128, 128, 0.283);
    border-radius: 15px;
    padding: 10px 5px;
}

.pdf-item {
    text-align: center;
    margin: 10px;
}

.pdf-item img {
    width: 50px;
    height: 50px;
}

.pdf-item p {
    margin-top: 5px;
}