.home h1 {
    font-size: 80px;
    color: red;
    margin: 0;
}

.banner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 90vh;
}

.banner>h2 {
    color: #66b2b2;
    font-size: 38px;
    width: 60%;
    text-align: center;
    margin: 0;

}

.banner>h1 {
    color: #004c4c;
    font-size: 108px;
    margin: 0;

}

.banner>a {
    width: 300px;
    background: #006666;
    padding: 10px 25px;
    text-align: center;
    color: white;
    border: none;
    font-size: 30px;
    border-radius: 15px;
    cursor: pointer;
}

.banner>a:hover {
    background: #008080;

}

.nolink {
    text-decoration: none;
    color: white;
}

.specific_location {
    font-size: 28px;
    margin: 0 0 42px 0;
    color: #66b2b2;

}
.sub_gallery {
    width: 90%;
    margin: auto;
    gap: 40px;
    display: flex;
    flex-direction: column;
}
#gallery{
    margin-bottom: 80px;
}
.slick-next:before,.slick-prev:before  {
    color: #004c4c!important;
    font-size: 30px!important;
}
.slick-slider{
    padding: 0 10px!important;
}
.slider_home {
    max-height: 50vh;
    width: 100%;
}
.sub_gallery img{
    width: 100%;
    max-height: 50vh;
}
.sub_gallery h2{
    text-align: center;
    font-size: 30px;
    color: #004c4c;
}
.slick-track{
    display: flex!important;
    gap: 10px;
}
.image_container{
    height: 400px;
    width: 400px;
}
.image_container>img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}
@media (max-width: 600px) {
    .sub_gallery h2 {
        text-align: center;
        font-size: 22px;
        color: #004c4c;
    }
    .sub_gallery {
        width: 83%;
        margin: auto;
        gap: 19px;
        display: flex;
        flex-direction: column;
    }
    .banner>h2 {
        color: #66b2b2;
        font-size: 21px;
        width: 90%;
        text-align: center;
        margin: 0;
    }

    .banner>h1 {
        color: #004c4c;
        font-size: 69px;
        margin: 0;
    }

    .banner>a {
        width: 300px;
        background: #006666;
        padding: 7px 10px;
        color: white;
        border: none;
        font-size: 24px;
        border-radius: 15px;
        cursor: pointer;
    }

}

/* Add this to your CSS file */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.banner>* {
    animation: fadeIn 1.5s ease-out;
    animation-fill-mode: backwards;
}