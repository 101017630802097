/* Contact.css */
.contact-container {
    /* padding: 20px; */
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.top_contact {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    gap: 10px;
    color: #004c4c;

}

h1 {
    color: #004c4c;
    font-size: 32px;
}

.contact-details {
    margin-top: 20px;
}

.contact-details p {
    margin-bottom: 10px;
    font-size: 18px;
}

.contact-details strong {
    margin-right: 10px;
    font-weight: bold;
    color: #006666;
}

@media (max-width: 600px) {
    h1 {
        color: #004c4c;
        font-size: 27px;
    }
    .contact-details p {
        margin-bottom: 10px;
        font-size: 16px;
    }
    
    .contact-container {
        padding: 0;
        justify-content: center;
        width: 95%;
        margin: auto;
        flex-direction: column;
        align-items: center;
    }    
}